import { EstateCardView, type EstateCardViewProps } from '@portals/core-immobilien/src/components';
import React from 'react';

import { useNoResultActionUtils } from './useNoResultActionUtils/useNoResultActionUtils';

export const EstateCardViewWrapper: React.FC<EstateCardViewProps> = (props): React.ReactElement => {
  const { isProcessing, handleAction } = useNoResultActionUtils();
  return <EstateCardView {...props} useNoResultActionUtils={{ isProcessing, handleAction }} />;
};

EstateCardViewWrapper.displayName = 'EstateCardViewWrapper';
