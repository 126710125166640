import { InternHeadline } from '@portals/root/models/intern/headline';
import {
  EstateListResponse,
  EstateObjectTypeOptions,
  EstateSearchProps,
} from '@portals/sip-client-data/src/general/ApiClientTypes';

interface EstateSearchParameters extends EstateSearchProps {
  __component: 'content.estate-search-parameters';
  id: string;
}

interface EstateObjectTypes extends EstateObjectTypeOptions {
  __component: 'content.estate-object-types';
  id: string;
}

export interface EstateList {
  __component: 'content.estate-list';
  id: string;
  ssr: boolean;
  headline?: InternHeadline;
  estateSearchParameters?: EstateSearchParameters;
  objectTypes?: EstateObjectTypes;
  estatesData?: EstateListResponse;
}

export function isEstateCardView(obj: { __component?: string }): obj is EstateList {
  return obj.__component === 'content.estate-list';
}
