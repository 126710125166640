import { EstateSearch, EstateSearchImmobilienApi } from '@portals/core-immobilien/src/components';
import { EstateSearchProps } from '@portals/core-immobilien/src/components/EstateSearch/EstateSearch.types';
import { ImmobilienApiSwitchContext } from '@portals/core-immobilien/src/context';
import React, { useContext } from 'react';

import { useEstateSearchHandler } from './useEstateSearchHandler/useEstateSearchHandler';

export const EstateSearchWrapper: React.FC<EstateSearchProps> = (props) => {
  // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: switch and context
  const { useImmobilienApi } = useContext(ImmobilienApiSwitchContext);

  const { handleEstateSearch } = useEstateSearchHandler();
  return useImmobilienApi ? (
    <EstateSearchImmobilienApi {...props} isSkeletonEnabled={true} updateEstateSearchHandler={handleEstateSearch} />
  ) : (
    <EstateSearch {...props} isSkeletonEnabled={true} updateEstateSearchHandler={handleEstateSearch} />
  );
};

EstateSearchWrapper.displayName = 'EstateSearchWrapper';
