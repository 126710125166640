import { ActionFavoriteButton, EstateListViewRenderProps } from '@portals/core-immobilien/src/components';
import React from 'react';

import { EstateLink } from '../EstateLink/EstateLink';

export const renderBookmarkButton: EstateListViewRenderProps['renderBookmarkButton'] = (estateId, size) => (
  <ActionFavoriteButton id={estateId} size={size} />
);

export const renderEstateLink: EstateListViewRenderProps['renderEstateLink'] = (estateId, children) => (
  <EstateLink id={estateId} key={estateId}>
    {children}
  </EstateLink>
);
